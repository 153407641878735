.App,
.Useful,
.Useless,
.Secret {
  font-family: "Comic Neue", cursive;
  font-weight: bold;
}

.App {
  background-color: #192f5e;
}
a:link {
  margin-right: 20px;
  text-decoration: none !important;
}

.navbar {
  background-color: #192f5e !important;
}
.navbar-links {
  color: white !important;
}
.navbar-links:hover {
  color: #bc3e44 !important;
}

.navbar-links-secret {
  color: #f4f7fa !important;
}
.navbar-links-secret:hover {
  cursor: help !important;
}

.background {
  position: flex;
  height: auto;
  width: 100%;
}

.background-right {
  height: auto;
  width: 32%;
}

.background-left {
  height: auto;
  width: 32%;
}

.homepage-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chippi-2025 {
  height: auto;
  width: 60%;
}

.next-year {
  text-align: center;
  font-size: 24px;
  border: 1px solid #f4f7fa;
  max-width: 75%;
  border-radius: 10px;
  margin: 0 auto;
}

.email-entry {
  background-color: black;
}

.date {
  text-align: center;
  font-size: 25px;
}

.details-div {
  text-align: center;
}
.whiskey-bar {
  max-height: auto;
  max-width: 100%;
}

.faq-list {
  text-align: left;
  font-size: 18px;
}

.faq {
  font-size: 24px;
}
li {
  font-weight: bold;
  font-size: 20px;
  color: #bf0a2f;
}
.info-list {
  list-style: none;
}

.useless-info {
  text-align: center;
  font-size: 20px;
}

.email-footer {
  margin: 0 auto;
  text-align: center;
  font-size: 18px;
  background-color: white;
  padding: 30px;
}
